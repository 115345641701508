import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import {
  START_FLOW_GENERAL_WEB,
  NUX_BURNER_COMPLETE_WEB,
  CUSTOM_WEB_EVENT,
} from '../constants';
import * as oauthActions from '../ducks/oauth';
import * as authActions from '../ducks/auth';
import { formatE164, isValidNumber } from '../utils/phone';
import Footer from '../components/oauth/Footer';
import OauthAwareButton from '../components/oauth/OauthAwareButton';
import OauthAwareHeader from '../components/oauth/OauthAwareHeader';
import { trackAmplitudeFailedEvent } from '../utils/analytics';
import { removeStorageSelectedNumber } from '../utils/sessionStorage';

const propTypes = {
  clientInfo: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    sampleSku: PropTypes.string,
    thumbnailURL: PropTypes.string,
  }),
  isAuthenticated: PropTypes.bool.isRequired,
};

const defaultProps = {
  clientInfo: null,
};

class SignupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      phone: '',
    };

    this.getInputPlaceholder = this.getInputPlaceholder.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderErrorSection = this.renderErrorSection.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderMetadata = this.renderMetadata.bind(this);
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    const { router } = this.context;

    removeStorageSelectedNumber();

    if (isAuthenticated) {
      dataLayer.push({
        event: CUSTOM_WEB_EVENT,
        customWebEventName: NUX_BURNER_COMPLETE_WEB,
      });
      router.push('/dashboard');
    } else {
      dataLayer.push({
        event: CUSTOM_WEB_EVENT,
        customWebEventName: START_FLOW_GENERAL_WEB,
      });
    }
  }

  getInputPlaceholder() {
    const { clientInfo } = this.props;

    if (clientInfo && clientInfo.sampleSku) {
      return 'XXX-XXX-XXXX';
    }
    return 'XXX-XXX-XXXX';
  }

  handlePhoneChange(values) {
    this.setState(() => ({
      errorMessage: null,
      phone: values.value,
    }));
  }

  handleSubmit(event) {
    event.preventDefault();

    const { phone } = this.state;
    const { router } = this.context;

    if (phone.length < 9) {
      trackAmplitudeFailedEvent('wrong phone number', 'web app');

      this.setState(() => ({
        errorMessage: 'The phone number is too short.',
      }));
      return;
    }

    if (!isValidNumber(phone)) {
      trackAmplitudeFailedEvent('wrong phone number', 'web app');

      this.setState(() => ({
        errorMessage: "That phone number doesn't look right.",
      }));
      return;
    }
    const formattedPhoneNumber = formatE164(phone);
    router.push(`/verify/${formattedPhoneNumber}`);
  }

  renderErrorSection() {
    const { errorMessage } = this.state;

    if (!errorMessage) {
      return null;
    }
    return <div className="error error-msg">{errorMessage}</div>;
  }

  renderTitle() {
    const { clientInfo } = this.props;

    if (clientInfo) {
      if (clientInfo.sampleSku) {
        return <span>Get your phone number by creating a Burner account</span>;
      }
      return (
        <span>Please log in to Burner to link with {clientInfo.name}</span>
      );
    }
    return <span>Enter your account phone number to log in</span>;
  }

  /**
   * Renders any metadata that's required
   */
  renderMetadata() {
    const { clientInfo } = this.props;

    if (clientInfo && clientInfo.sampleSku) {
      return (
        <span>
          {clientInfo.name} uses Burner for private phone numbers. We’ll send
          you an SMS to verify. This information is private and will not be
          shared with {clientInfo.name}.
        </span>
      );
    }
  }

  render() {
    const { errorMessage } = this.state;
    const { clientInfo } = this.props;

    return (
      <div>
        <OauthAwareHeader clientInfo={clientInfo} />
        <div className="Main__container Main__container--minHeight grid-x grid-padding-x align-center-middle">
          <div className="cell large-8 small-10">
            <form onSubmit={this.handleSubmit}>
              <h1 className="desc text-center">{this.renderTitle()}</h1>
              <div className="Form__inputWrapper u-margin-top-35px grid-x grid-padding-x align-center align-bottom">
                <label
                  htmlFor="phone"
                  className="Form__fieldWrapper cell large-4 auto"
                >
                  <span className="Input__label">Account phone number</span>
                  <NumberFormat
                    id="phone"
                    type="tel"
                    format="(###) ###-####"
                    onValueChange={this.handlePhoneChange}
                    className={`Input__field main-input  ${
                      errorMessage ? 'error-input' : ''
                    }`}
                    placeholder={this.getInputPlaceholder()}
                  />
                </label>
                <div className="Form__submitWrapper cell shrink">
                  <OauthAwareButton
                    clientInfo={clientInfo}
                    className="flat button button--notRounded submit"
                    buttonText="Next"
                  />
                </div>
              </div>
              <div className="Form__outputWrapper u-margin-top-35px grid-x align-center text-center">
                <div className="cell large-8">
                  {this.renderErrorSection()}
                  {this.renderMetadata()}
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

SignupPage.propTypes = propTypes;
SignupPage.defaultProps = defaultProps;
SignupPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = function mapStateToProps(state) {
  return {
    clientInfo: state.oauthPage.clientInfo,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = { ...authActions, ...oauthActions };

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
