import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as amplitude from '@amplitude/analytics-browser';

import {
  AMPLITUDE_CREATE_ACCOUNT_VIEW,
  STRIPE_KEY,
} from '../constants';
import * as actionCreators from '../ducks/subscribe';
import { formatE164 } from '../utils/phone';
import { amplitudeDeviceProperty } from '../utils/analytics';
import PurchasePage from '../pages/PurchasePage';
import { getStorageNumber } from '../utils/sessionStorage';

const propTypes = {
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  numberAssociated: PropTypes.bool,
  selectedNumber: PropTypes.string.isRequired,
  selectedSku: PropTypes.string.isRequired,
  subscription: PropTypes.shape({
    totalConcurrentBurners: PropTypes.number,
    totalBurnersAllowedInPeriod: PropTypes.number,
    sku: PropTypes.string,
  }),
};

const defaultProps = {
  numberAssociated: false,
  subscription: null,
};

class PurchasePageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentNumber: this.props.selectedNumber,
    }

    this.handleGetNumber = this.handleGetNumber.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  componentDidMount() {
    amplitude.track(AMPLITUDE_CREATE_ACCOUNT_VIEW, amplitudeDeviceProperty);
    Stripe.setPublishableKey(STRIPE_KEY);

    const number = getStorageNumber();

    if (!!number) {
      this.handleGetNumber(number)
    }
  }

  componentDidUpdate() {
    const { subscription, selectedNumber, errorNumber, isLoading } = this.props;
    const { router } = this.context;
    const isActiveSelectedNumber = (subscription && selectedNumber)
    const isWrongSelectedNumber = subscription && !selectedNumber && errorNumber;

    if (!isLoading && (isActiveSelectedNumber || isWrongSelectedNumber)) {
      router.push('/confirmation');
    }
  }

  handleGetNumber(number) {
    const { selectedNumber } = this.props;

    if (!selectedNumber) {
      this.setState({
        currentNumber: number,
      })
    }
  }

  handleSubmitForm() {
    const { form, handleSubmit, selectedSku } = this.props;

    handleSubmit(formatE164(form.subscribe.values.phoneNumber), selectedSku);
  }

  render() {
    const cellPhone = require('images/subscribe/concept2/cell-phone_static.png');
    const { paymentError } = this.props;
    const { currentNumber } = this.state;

    return (
      <PurchasePage
        handleSubmitForm={this.handleSubmitForm}
        selectedNumber={currentNumber}
        cellPhone={cellPhone}
        paymentError={paymentError}
      />
    );
  }
}

PurchasePageContainer.propTypes = propTypes;
PurchasePageContainer.defaultProps = defaultProps;
PurchasePageContainer.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
  return {
    form: state.form,
    numberAssociated: state.subscribePage.numberAssociated,
    selectedNumber: state.subscribePage.selectedNumber,
    selectedSku: state.subscribePage.selectedSku,
    subscription: state.subscribePage.subscription,
    paymentError: state.subscribePage.paymentError,
    errorNumber: state.subscribePage.errorNumber,
    isLoading: state.loading.isLoading,
  }
};

const mapDispatchToProps = actionCreators;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchasePageContainer);
