import axios from 'axios';
import cookie from 'react-cookie';
import {
  AUTHENTICATION_KEY,
  CURRENT_USER,
  BASE_URL,
  IS_SECURED,
  AMPLITUDE_LOGIN_SUCCESS,
} from '../constants';
import { hasError, validationCodeError, VERIFY_CODE_ERROR, RESET_VERIFY_CODE_ERROR } from './error';
import { requesting, REQUESTING } from './general';
import { getCountry } from '../utils/phone';
import { getCurrentUser } from '../utils/user';
import { extractError } from '../utils/request';
import { amplitudeDeviceProperty, trackAmplitudeFailedEvent } from '../utils/analytics';
import * as amplitude from '@amplitude/analytics-browser';
import { setPaymentError } from './subscribe';

export const RECEIVE_CODE = 'burner-app/verify/RECEIVE_CODE';
export const USER_VERIFIED = 'burner-app/verify/USER_VERIFIED';
export const LOGOUT_SUCCESS = 'burner-app/verify/LOGOUT_SUCCESS';
export const SUBSCRIPTION_CREATED = 'burner-app/verify/SUBSCRIPTION_CREATED';
export const SUBSCRIBE_MODAL_TOGGLED = 'burner-app/verify/SUBSCRIBE_MODAL_TOGGLED';

/**
 * Reducer for the verify/login page
 */
export default function reducer(state = { isFetching: false }, action = {}) {
  switch (action.type) {
    case REQUESTING:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_CODE:
      return Object.assign({}, state, {
        codeWasSent: true,
        isFetching: false,
        isResend: action.isResend,
      });
    case USER_VERIFIED:
      return Object.assign({}, state, {
        verifyErrorMessage: null,
        userVerified: true,
        isFetching: false,
        codeWasSent: false,
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        userVerified: false,
      });
    case SUBSCRIPTION_CREATED:
      return Object.assign({}, state, {
        codeWasSent: false, // disable this once sub is successfully created
      });
    case VERIFY_CODE_ERROR:
      return Object.assign({}, state, {
        verifyErrorMessage: action.error,
        isFetching: false,
        codeWasSent: true,
      });
    case RESET_VERIFY_CODE_ERROR:
      return Object.assign({}, state, {
        verifyErrorMessage: null,
      });
    case SUBSCRIBE_MODAL_TOGGLED:
      return Object.assign({}, state, {
        codeWasSent: false,
      });
    default:
      return state;
  }
}

/**
 * Happens when network request comes through for requesting the verify code
 */
function receiveVerifyCode(isResend, sku) {
  return {
    type: RECEIVE_CODE,
    receivedAt: Date.now(),
    isResend,
    sku,
  };
}

/**
 * Fetches the verify code. Note that sku is optional, to be sent along to the receive verify code dispatch.
 */
export function fetchVerifyCode(phone, isResend = false, sku, errorAction = null, authToken) {
  const errorActionHash = { goBack: errorAction, redirectBackAction: errorAction };
  const authHeader = authToken ? { Authentication: authToken } : {};

  return (dispatch) => {
    dispatch(requesting());
    return axios
      .post(
        `${BASE_URL}/verifyCode/${phone}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            ...authHeader,
          },
        }
      )
      .then(() => {
        dispatch(receiveVerifyCode(isResend, sku));
        dispatch(setPaymentError(null));
      })
      .catch((error) => {
        trackAmplitudeFailedEvent('unsupported carrier');

        dispatch(setPaymentError(null));
        dispatch(Object.assign({},
          errorActionHash,
          hasError(extractError(error),
            error.status,
            null,
            errorActionHash.redirectBackAction
          )
        ))
      });
  };
}

function userVerified() {
  return {
    type: USER_VERIFIED,
    authToken: cookie.load(AUTHENTICATION_KEY),
    user: getCurrentUser(),
  };
}

// will save the authentication token and user info
export function receiveValidateVerifyCode(authToken, user) {
  return (dispatch) => {
    // save the authToken
    cookie.save(AUTHENTICATION_KEY, authToken, { path: '/', secure: IS_SECURED });
    // save the user
    cookie.save(CURRENT_USER, user, { path: '/', secure: IS_SECURED });

    dispatch(userVerified());
  };
}

export function validateVerifyCode(phone, pin) {
  return (dispatch) => {
    dispatch(requesting());

    return axios
      .post(
        `${BASE_URL}/register`,
        {
          country: getCountry(phone),
          phoneNumber: phone,
          code: pin,
          trackingId: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((resp) => {
        const authHeader = resp.headers.authentication;

        if (authHeader) {
          amplitude.track(AMPLITUDE_LOGIN_SUCCESS, {
            provider: 'phone',
            method: 'phone',
            ...amplitudeDeviceProperty,
          })
          amplitude.setUserId(resp.data.id);

          dispatch(receiveValidateVerifyCode(authHeader, resp.data));

          return { authToken: authHeader, user: resp.data };
        }
      })
      .catch((error) => {
        if (extractError(error)?.includes('expired')) {
          trackAmplitudeFailedEvent('expired verification code');
        } else {
          trackAmplitudeFailedEvent('invalid phone verification - signin');
        }

        dispatch(validationCodeError(extractError(error)));
      });
  };
}
