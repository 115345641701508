import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AMPLITUDE_KEY, amplitudeProperties, ENVIRONMENT } from '../constants';
import ErrorModal from '../components/modal/ErrorModal';
import Spinner from '../components/Spinner';
import '../../styles/vendor/typicons.css';
import '../../styles/vendor/animate.css';
import '!style-loader!css-loader!sass-loader!../../styles/vendor/foundation/foundation.scss';
import '!style-loader!css-loader!sass-loader!../../styles/_custom-webfont.scss';
import '!style-loader!css-loader!sass-loader!../../styles/utils-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/application-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/modal.scss';
import '!style-loader!css-loader!sass-loader!../../styles/modal-responsive.scss';
import '!style-loader!css-loader!sass-loader!../../styles/spinner.scss';
import '!style-loader!css-loader!sass-loader!../../styles/typography-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/form-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/buttons.scss';
import '!style-loader!css-loader!sass-loader!../../styles/header-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/footer-rebrand.scss';
// load jquery and foundation in the window scope
import 'script-loader!what-input';
import 'core-js';
import { Foundation } from 'foundation-sites';
import jquery from 'jquery';
import * as amplitude from '@amplitude/analytics-browser';

window.jQuery = jquery;
window.$ = jquery;

Foundation.addToJquery($);

amplitude.init(AMPLITUDE_KEY, amplitudeProperties);

const propTypes = {
  children: PropTypes.node.isRequired, // Injected by React Router
  redirectBackAction: PropTypes.func,
};

const defaultProps = {
  redirectBackAction: () => {},
};

class App extends Component {
  componentWillMount() {
    dataLayer.push({ env: ENVIRONMENT });
  }

  componentDidUpdate() {
    $(document).foundation();
    window.scrollTo(0, 0);
  }

  render() {
    const { children, redirectBackAction } = this.props;

    return (
      <div>
        {children}
        <Spinner />
        <ErrorModal redirectBackAction={redirectBackAction} />
      </div>
    );
  }
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(App);
