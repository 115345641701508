import React from 'react';
import { US, AU } from '../constants';
import PhoneFormat from 'phoneformat.js';
import { trackAmplitudeFailedEvent } from './analytics';
import { parsePhoneNumberFromString, parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  value === '' ||
  value === 'undefined';

export function isValidNumber(number) {
  return (
    isValidPhoneNumber(number, US)
    || isValidPhoneNumber(number, AU)
  );
}

/**
 * Formats a number to e164. Assumes that the phone is either a valid US/CA or AU number
 */
export function formatE164(number) {
  if (!isEmpty(number)) {
    if (PhoneFormat.isValidNumber(number, US)) {
      return PhoneFormat.formatE164(US, number);
    }

    return PhoneFormat.formatE164(AU, number);
  }
}

/**
 * Gets the country of a number.
 * @param number
 */
export function getCountry(number) {
  if (!isEmpty(number)) {
    const phoneNumberUS = parsePhoneNumberFromString(number.toString(), US);
    const phoneNumberAU = parsePhoneNumberFromString(number.toString(), AU);

    if (phoneNumberUS && phoneNumberUS.isValid()) {
      return US;
    } else if (phoneNumberAU && phoneNumberAU.isValid()) {
      return AU;
    }
  }
}

export function formatLocal(number) {
  if (!isEmpty(number) && isValidNumber(number)) {
    const phoneNumber = parsePhoneNumber(number, getCountry(number));

    // receive the phone number in the format +1 xxx xxx xxx and convert it to +1 xxx-xxx-xxx
    const separateNumber = phoneNumber.formatInternational().split(' ').slice(1);
    const numberWithDots = separateNumber.join('-');

    return `+${phoneNumber.countryCallingCode} ${numberWithDots}`;
  }

  return '';
}

export function renderLocalNumber(number) {
  if (!isEmpty(number)) {
    number = formatLocal(number).split(' '); // remove the country code
    number = number[1].split('-');
    return `(${number[0]}) ${number[1]}-${number[2]}`;
  }
}

export function removeCountryCode(number) {
  if (!isEmpty(number)) {
    number = formatLocal(number).split(' '); // remove the country code
    return number[1]
  }
}

export function renderNumber(number) {
  if (!isEmpty(number)) {
    number = formatLocal(number).split(' '); // remove the country code
    number = number[1].split('-'); // split into blocks
    return (
      <div>
        <span className="PickNumber__areaCode">({number[0]})</span> {number[1]}-
        {number[2]}
      </div>
    );
  }
}

export function validateAreaCode(areaCode) {
  if (!areaCode || areaCode.length !== 3) {
    trackAmplitudeFailedEvent('invalid area code', 'web app');

    return 'Area code must be 3 digits.';
  } else if (areaCode.slice(0, 1) === '0') {
    trackAmplitudeFailedEvent('invalid area code', 'web app');

    return 'Please enter a valid US or Canadian area code.';
  }
}
