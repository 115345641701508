import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  AMPLITUDE_AREA_CODE_VIEW,
  CUSTOM_WEB_EVENT,
  experimentParams,
  FIND_NUMBER,
  START_FLOW_SQUEEZE_WEB,
  STRIPE_KEY,
} from '../constants';
import * as actionCreators from '../ducks/subscribe';
import * as chooseActions from '../ducks/chooseNumber';
import AreaCodeInput from '../components/subscribe/AreaCodeInput';
import Header from '../components/common/Header';
import Footer from '../components/oauth/Footer';
import '!style-loader!css-loader!sass-loader!../../styles/subscribe-rebrand.scss';
import '!style-loader!css-loader!sass-loader!../../styles/number-picker.scss';
import * as amplitude from '@amplitude/analytics-browser';
import { amplitudeDeviceProperty } from '../utils/analytics';
import { removeStorageSelectedNumber } from '../utils/sessionStorage';

const propTypes = {
  location: PropTypes.object.isRequired,
  resetSearch: PropTypes.func.isRequired,
  setMaxSearchResults: PropTypes.func.isRequired,
};

/**
 * Subscribe page that looks like the home page.
 */
class SubscribePage extends Component {
  constructor(props) {
    super(props);

    this.areaCodeChanged = this.areaCodeChanged.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderNumberPicker = this.renderNumberPicker.bind(this);
    this.findNumbers = this.findNumbers.bind(this);
  }

  componentDidMount() {
    const { location, setMaxSearchResults } = this.props;

    removeStorageSelectedNumber();

    Stripe.setPublishableKey(STRIPE_KEY);
    dataLayer.push({
      event: CUSTOM_WEB_EVENT,
      customWebEventName: START_FLOW_SQUEEZE_WEB,
    });
    $(document).foundation();

    amplitude.track(AMPLITUDE_AREA_CODE_VIEW, amplitudeDeviceProperty);

    const query = location.query;
    const results = query.results;

    if (results) {
      setMaxSearchResults(parseInt(results, 10));
    }
  }

  findNumbers(areaCode) {
    const { requestNumber } = this.props;

    dataLayer.push({
      event: CUSTOM_WEB_EVENT,
      customWebEventName: FIND_NUMBER,
      customWebEventAction: areaCode,
    });

    requestNumber(areaCode);
  }

  areaCodeChanged(areaCode) {
    const { location, resetSearch } = this.props;
    const { router } = this.context;
    const queryParams = { ...location.query };
    let newParams;

    /* Handle query parameters passed in for experiments */
    for (const param in queryParams) {
      if (queryParams.hasOwnProperty(param)) {
        if (experimentParams.indexOf(param) > -1) {
          newParams = newParams
            ? `${newParams}&${param}=${queryParams[param]}`
            : `?${param}=${queryParams[param]}`;
        }
      }
    }
    resetSearch();
    this.findNumbers(areaCode);
    router.push(`/areacode/${areaCode}`);
  }

  renderForm() {
    return (
      <div className="text-center cell large-10 small-10">
        {this.renderNumberPicker()}
      </div>
    );
  }

  renderNumberPicker() {
    return (
      <div>
        <h1 className="centered" id="search-page-headline">
          Choose an area code
        </h1>
        <h2 className="h4">
          Burner includes unlimited calls, texts, and pics plus spam blocking.
        </h2>

        <AreaCodeInput
          template="SubscribePage"
          onAreaCodeEnter={this.areaCodeChanged}
        />
      </div>
    );
  }

  renderPress() {
    const pressLogosArray = [
      {
        img: require('images/press/logos/logo-ny-times.png'),
        pressName: 'The New York Times',
        maxHeight: '26px',
      },
      {
        img: require('images/press/logos/logo-lifehacker.png'),
        pressName: 'Lifehacker',
        maxHeight: '30px',
      },
      {
        img: require('images/press/logos/logo-time.png'),
        pressName: 'Time Magazine',
        maxHeight: '28px',
      },
      {
        img: require('images/press/logos/logo-techcrunch.png'),
        pressName: 'TechCrunch',
        maxHeight: '26px',
      },
      {
        img: require('images/press/logos/logo-tnw.png'),
        pressName: 'The Next Web - TNW',
        maxHeight: '26px',
      },
      {
        img: require('images/press/logos/logo-cnet.png'),
        pressName: 'CNET',
        maxHeight: '58px',
      },
      {
        img: require('images/press/logos/logo-urban-daddy.png'),
        pressName: 'Urban Daddy',
        maxHeight: '16px',
      },
      {
        img: require('images/press/logos/logo-mashable.png'),
        pressName: 'Mashable',
        maxHeight: '24px',
      },
    ];

    const pressLogosRender = pressLogosArray
      .reduce(
        (rows, key, index) =>
          (index % 2 === 0
            ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows,
        []
      )
      .map((row, rowIndex) => (
        <div
          key={`press-row-${rowIndex}`}
          className="PressLogo__column cell large-3 small-6"
        >
          {row.map((col, colIndex) => (
            <div
              key={`press-item-${colIndex}`}
              className="PressLogo__imgWrapper"
              style={{
                backgroundImage: `url(${col.img})`,
                maxHeight: `${col.maxHeight}`,
              }}
            ></div>
          ))}
        </div>
      ));

    return (
      <div className="text-center Press__container cell medium-8 large-7 small-11">
        <div className="grid-x align-center-middle">{pressLogosRender}</div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Header />
        <div className="Main__container Main__container--minHeight grid-x grid-padding-x align-center-middle">
          {this.renderForm()}
        </div>
        <div className="Press grid-x grid-padding-x align-center-middle">
          {this.renderPress()}
        </div>
        <Footer />
      </div>
    );
  }
}

SubscribePage.propTypes = propTypes;
SubscribePage.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapDispatchToProps = { ...actionCreators, ...chooseActions };

export default connect(null, mapDispatchToProps)(SubscribePage);
