export const AUTHENTICATION_KEY = 'burner_token';
export const CURRENT_USER = 'burner_user';
export const US = 'US';
export const AU = 'AU';

let tBASE_URL = 'https://phoenix.burnerapp.com';
if (__DEVELOPMENT__) {
  tBASE_URL = 'https://dev-api.burnerapp.com';
} else if (__QA__) {
  tBASE_URL = 'https://api-qa.burnerapp.com';
} else if (__QA1__) {
  tBASE_URL = 'https://qa1-api.burnerapp.com';
} else if (__QA2__) {
  tBASE_URL = 'https://qa2-api.burnerapp.com';
}

let tV2_BASE_URL = 'https://phoenix.burnerapp.com/v2';
if (__DEVELOPMENT__) {
  tV2_BASE_URL = 'https://dev-api.burnerapp.com/v2';
} else if (__QA__) {
  tV2_BASE_URL = 'https://api-qa.burnerapp.com/v2';
} else if (__QA1__) {
  tV2_BASE_URL = 'https://qa1-api.burnerapp.com/v2';
} else if (__QA2__) {
  tV2_BASE_URL = 'https://qa2-api.burnerapp.com/v2';
}

export const BASE_URL = tBASE_URL;
export const V2_BASE_URL = tV2_BASE_URL;
export const IS_SECURED = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? false : true;
export const STRIPE_KEY =
  __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__
    ? 'pk_test_rLO7bWBqKLeKOQ7FgldSjw9l'
    : 'pk_live_IJdmz3jg8tkhMYiyxi1lI6kq';
export const ENVIRONMENT = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? 'QA' : 'Production';
export const GA_KEY = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__ ? 'G-QX429K8MXF' : 'G-R146Q8ESM5';
export const LEAN_PLUM_APP_ID = 'UhwDbmKYwRee5mbtJc3QuLp3RZpef4wMUPM88Gyonmc';
export const LEAN_PLUM_PROD_KEY = 'uwBXyPPDxEl7SalLs0XwooGkbGdFnEaLNVB633YR8Fo';
export const DEFAULT_SKU = 'com.adhoclabs.burner.subscription.month.1';
export const OAUTH_REDIRECT_PARAMS = 'oath_redirect_params';
export const SELECTED_NUMBER = 'SELECTED_NUMBER';
export const SELECTED_PLAN = 'SELECTED_PLAN'

// analytics related
export const CUSTOM_WEB_EVENT = 'customWebEvent';
export const CUSTOM_WEB_PAGEVIEW = 'customWebPageview';
export const SITEBUILDER_ADJUST = 'https://app.adjust.com/nhka8el_n5ufovg?fallback=http%3A%2F%2Fburnerapp.com';
export const AMPLITUDE_KEY = __DEVELOPMENT__ || __QA__ || __QA1__ || __QA2__
  ? '535c4199d1abeb892baf471d2e6e81f5' : '73edfffeb77c887aae4c0c2e3c7eb7a6';

// event names
export const START_FLOW_SQUEEZE_WEB = 'start_flow_squeeze_web';
export const PURCHASED = 'purchase';
export const SIGNUP = 'signup';
export const START_FLOW_GENERAL_WEB = 'start_flow_general_web';
export const NUX_NUMBER_VERIFICATION_WEB = 'nux_number_verification_web';
export const NUX_NUMBER_SELECTION_WEB = 'nux_number_selection_web';
export const NUX_BURNER_COMPLETE_WEB = 'nux_burner_complete_web';
export const FIND_NUMBER = 'find_number';
export const NUMBERS_RECEIVED = 'numbers_received';
export const CHOOSE_NUMBER = 'choose_number';
export const RESELECT_AREA_CODE = 'reselect_area_code';
export const SUBMIT_PAYMENT_INFO = 'submit_payment_info';
export const ERROR_EVENT = 'error';
export const SUBSCRIPTION_PURCHASE_START = 'subscription_purchase_start';
export const WEB_SUBSCRIPTION_EVENT = 'web_subscription_signup';
export const AMPLITUDE_AREA_CODE_VIEW = 'web_burner_create_area_code_view';
export const AMPLITUDE_PAYWALL_VIEW = 'web_paywall_view';
export const AMPLITUDE_CREATE_ACCOUNT_VIEW = 'web_create_account_view';
export const AMPLITUDE_VERIFY_CODE_SENT = 'web_verify_code_sent';
export const AMPLITUDE_SIGNUP_SUCCESS = 'web_account_sign_up_success';
export const AMPLITUDE_WEB_SUBSCRIPTION_PURCHASE_SUCCESS = 'web_subscription_purchase_success';
export const AMPLITUDE_LOGIN_SUCCESS = 'web_account_log_in_success';
export const AMPLITUDE_PROPERTY_DEVICE = 'web_device_type';
export const AMPLITUDE_WEB_FAILURE = 'web_failure';

// oauth scopes
export const OAUTH_MESSAGES_CONNECT = 'messages:connect';
export const OAUTH_BURNERS_READ = 'burners:read';
export const OAUTH_BURNERS_WRITE = 'burners:write';
export const OAUTH_CONTACTS_READ = 'contacts:read';
export const OAUTH_CONTACTS_WRITE = 'contacts:write';

/* External Links */
export const SUPPORT_ARTICLE_CANCEL_SUBSCRIPTION = 'https://support.burnerapp.com/article/205-how-do-i-cancel-my-subscription'

export function getHumanScopeText(scope, burnerNames) {
  switch (scope) {
    case OAUTH_MESSAGES_CONNECT:
      return {
        __html: `<b>Send</b> outbound and <b>view</b> incoming text messages with ${burnerNames.join(', ')}`,
      };
    case OAUTH_BURNERS_READ:
      return { __html: '<b>Read</b> information about your active Burners' };
    case OAUTH_BURNERS_WRITE:
      return { __html: '<b>Edit</b> settings for active Burners' };
    case OAUTH_CONTACTS_READ:
      return { __html: '<b>Read</b> information about your Burner contacts' };
    case OAUTH_CONTACTS_WRITE:
      return { __html: '<b>Edit</b> information about your Burner contacts' };
    default:
      return null;
  }
}

/*
 * Current SKUs for testing.
 * default is the current in-app pricing
 * offerset1w and offerset1m are free trials (1 week or 1 month). Full price for all plans.
 * offerset15 is 15% off yearly plans, offerset40 is 40% off yearly plans.
 */

export const subscriptionSkuWithPrice = {
  default: {
    oneLineMonthly: {
      sku: 'com.adhoclabs.burner.subscription.month.1',
      price: '4.99',
      numLines: 1,
      isYearly: false,
    },
    oneLineYearly: {
      sku: 'com.adhoclabs.burner.subscription.year.1',
      price: '47.99',
      numLines: 1,
      isYearly: true,
    },
    threeLinesMonthly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.month.1',
      price: '14.99',
      numLines: 3,
      isYearly: false,
    },
    threeLinesYearly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.year.1',
      price: '139.99',
      numLines: 3,
      isYearly: true,
    },
  },
  offerset1w: {
    oneLineMonthly: {
      sku: 'com.adhoclabs.burner.subscription.month.1.trial.week.1',
      price: '4.99',
      numLines: 1,
      freeTrialLength: 7,
      isYearly: false,
    },
    oneLineYearly: {
      sku: 'com.adhoclabs.burner.subscription.year.1.trial.week.1',
      price: '47.99',
      numLines: 1,
      freeTrialLength: 7,
      isYearly: true,
    },
    threeLinesMonthly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.month.1.trial.week.1',
      price: '14.99',
      numLines: 3,
      freeTrialLength: 7,
      isYearly: false,
    },
    threeLineMonthlyVariantOne: {
      sku: 'com_adhoclabs_burner_subscription_concurrent_3_month_1_variant_1_trial_week_1',
      price: '9.99',
      numLines: 3,
      freeTrialLength: 7,
      isYearly: false,
    },
    threeLinesYearly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.year.1.trial.week.1',
      price: '139.99',
      numLines: 3,
      freeTrialLength: 7,
      isYearly: true,
    },
  },
  offerset1m: {
    oneLineMonthly: {
      sku: 'com.adhoclabs.burner.subscription.month.1.trial.month.1',
      price: '4.99',
      numLines: 1,
      freeTrialLength: 30,
      isYearly: false,
    },
    oneLineYearly: {
      sku: 'com.adhoclabs.burner.subscription.year.1.trial.month.1',
      price: '47.99',
      numLines: 1,
      freeTrialLength: 30,
      isYearly: true,
    },
    threeLinesMonthly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.month.1.trial.month.1',
      price: '14.99',
      numLines: 3,
      freeTrialLength: 30,
      isYearly: false,
    },
    threeLinesYearly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.year.1.trial.month.1',
      price: '139.99',
      numLines: 3,
      freeTrialLength: 30,
      isYearly: true,
    },
  },
  offerset15: {
    oneLineMonthly: {
      sku: 'com.adhoclabs.burner.subscription.month.1',
      price: '4.99',
      numLines: 1,
      isYearly: false,
    },
    oneLineYearly: {
      sku: 'com.adhoclabs.burner.subscription.year.1.variant.1',
      price: '50.99',
      numLines: 1,
      isYearly: true,
    },
    threeLinesMonthly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.month.1',
      price: '14.99',
      numLines: 3,
      isYearly: false,
    },
    threeLineMonthlyVariantOne: {
      sku: 'com_adhoclabs_burner_subscription_concurrent_3_month_1_variant_1_trial_week_1',
      price: '9.99',
      numLines: 3,
      freeTrialLength: 7,
      isYearly: false,
    },
    threeLinesYearly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.year.1.variant.1',
      price: '152.99',
      numLines: 3,
      isYearly: true,
    },
  },
  offerset40: {
    oneLineMonthly: {
      sku: 'com.adhoclabs.burner.subscription.month.1',
      price: '4.99',
      numLines: 1,
      isYearly: false,
    },
    oneLineYearly: {
      sku: 'com.adhoclabs.burner.subscription.year.1.variant.2',
      price: '35.99',
      numLines: 1,
      isYearly: true,
    },
    threeLinesMonthly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.month.1',
      price: '14.99',
      numLines: 3,
      isYearly: false,
    },
    threeLineMonthlyVariantOne: {
      sku: 'com_adhoclabs_burner_subscription_concurrent_3_month_1_variant_1_trial_week_1',
      price: '9.99',
      numLines: 3,
      freeTrialLength: 7,
      isYearly: false,
    },
    threeLinesYearly: {
      sku: 'com.adhoclabs.burner.subscription.concurrent.3.year.1.variant2',
      price: '107.99',
      numLines: 3,
      isYearly: true,
    },
  },
  mycorporation: {
    oneLineYearly: {
      sku: 'com.adhoclabs.burner.subscription.year.1.mycorporation1',
      price: '36.00',
      numLines: 1,
      isYearly: true,
    },
  },
};

/* Experiment Query Parameters */
export const experimentParams = ['offerset', 'exp'];
export const subscriptionType = {
  yearly: 'yearly',
  monthly: 'monthly',
}

// amplitude.analytic setup

export const amplitudeProperties = {
  defaultTracking: {
    attribution: true,
    pageViews: false,
    sessions: true,
    formInteractions: false,
    fileDownloads: false,
  },
}

export const signupFieldLabel = {
  firstName: 'first name',
  lastName: 'last name',
  email: 'email',
  phoneNumber: 'phone number',
  creditCard: 'card number',
  expMonth: 'expired month',
  expYear: 'expired year',
  cvc: 'cvc',
  zipCode: 'zip code',
}
