import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as amplitude from '@amplitude/analytics-browser';

import {
  SUBSCRIPTION_PURCHASE_START,
  CUSTOM_WEB_EVENT,
  subscriptionSkuWithPrice,
  AMPLITUDE_PAYWALL_VIEW,
} from '../constants';
import * as actionCreators from '../ducks/subscribe';
import { toMonthlyPrice } from '../utils/subscription';
import ChooseSubscriptionPage from '../pages/ChooseSubscriptionPage';
import { amplitudeDeviceProperty } from '../utils/analytics';
import { addStorageSubscription, getStorageNumber, removeStoragePlan } from '../utils/sessionStorage';

const propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  selectSubscriptionPlan: PropTypes.func.isRequired,
};

class ChooseSubscriptionPageContainer extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;

    this.priceVariation = location.query.offerset;
    this.selectPlan = this.selectPlan.bind(this);

    switch (this.priceVariation) {
      case 'offerset15':
        this.discountPercent = '15';
        break;
      case 'offerset40':
        this.discountPercent = '40';
        break;
      default:
        this.discountPercent = false;
        break;
    }

    // If anyone tries to change the query param to something else, don't throw an error.
    if (!(this.priceVariation in subscriptionSkuWithPrice)) {
      this.priceVariation = 'default';
    }
  }

  componentDidMount() {
    removeStoragePlan();

    amplitude.track(AMPLITUDE_PAYWALL_VIEW, amplitudeDeviceProperty)
  }

  selectPlan(plan) {
    const { selectSubscriptionPlan, selectedNumber } = this.props;
    const { router } = this.context;
    const storageNumber = getStorageNumber();

    dataLayer.push({
      event: CUSTOM_WEB_EVENT,
      customWebEventName: SUBSCRIPTION_PURCHASE_START,
      customWebEventAction: plan.sku,
      customWebEventLabel: plan.price,
    });

    if (selectedNumber || storageNumber) {
      addStorageSubscription(plan)
    }

    selectSubscriptionPlan(plan);
    router.push('/checkout');
  }

  render() {
    const oneLineMonthly = subscriptionSkuWithPrice[this.priceVariation].oneLineMonthly;
    const oneLineYearly = subscriptionSkuWithPrice[this.priceVariation].oneLineYearly;
    const featureIconUnlimited = require('images/subscribe/icons/icon_unlimited@3x.png');
    const featureIconAutoRenew = require('images/subscribe/icons/icon_autorenew@3x.png');
    const featureIconSpam = require('images/subscribe/icons/icon_spam@3x.png');
    const featureIconSwap = require('images/subscribe/icons/icon_swaps@3x.png');

    return (
      <ChooseSubscriptionPage
        discountPercent={this.discountPercent}
        priceVariation={this.priceVariation}
        selectPlan={this.selectPlan}
        toMonthlyPrice={toMonthlyPrice}
        oneLineMonthly={oneLineMonthly}
        oneLineYearly={oneLineYearly}
        featureIconUnlimited={featureIconUnlimited}
        featureIconAutoRenew={featureIconAutoRenew}
        featureIconSpam={featureIconSpam}
        featureIconSwap={featureIconSwap}
      />
    );
  }
}

ChooseSubscriptionPageContainer.propTypes = propTypes;
ChooseSubscriptionPageContainer.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapDispatchToProps = actionCreators;
function mapStateToProps(state) {
  return {
    selectedNumber: state.subscribePage.selectedNumber,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseSubscriptionPageContainer);
