import { SELECTED_NUMBER, SELECTED_PLAN } from '../constants';

export function removeStorageSelectedNumber() {
  sessionStorage.removeItem(SELECTED_PLAN);
  return sessionStorage.removeItem(SELECTED_NUMBER);
}

export function addStorageSelectedNumber(phoneNumber) {
  return sessionStorage.setItem(SELECTED_NUMBER, phoneNumber);
}

export function getStorageNumber() {
  return sessionStorage.getItem(SELECTED_NUMBER);
}

export function addStorageSubscription(plan) {
  sessionStorage.setItem(SELECTED_PLAN, JSON.stringify(plan));
}

export function removeStoragePlan() {
  return sessionStorage.removeItem(SELECTED_PLAN);
}

export function getStoragePlanData(name) {
  const retrievedData = sessionStorage.getItem(SELECTED_PLAN);
  const parsedData = JSON.parse(retrievedData);

  if (name && parsedData) {
    return parsedData[name];
  }

  return parsedData;
}
