import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actionCreators from '../ducks/subscribe';
import PickNumberPage from '../pages/PickNumberPage';

const propTypes = {
  isNumberTaken: PropTypes.bool,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  reprovisionNumber: PropTypes.func.isRequired,
  setSelectedNumber: PropTypes.func.isRequired,
  subscription: PropTypes.shape({
    totalConcurrentBurners: PropTypes.number,
    totalBurnersAllowedInPeriod: PropTypes.number,
    sku: PropTypes.string,
  }),
};

const defaultProps = {
  isNumberTaken: false,
  subscription: null,
};

class PickNumberPageContainer extends Component {
  constructor(props) {
    super(props);
    const { location, params } = this.props;

    this.areaCode = params.areaCode || location.query.areacode || '';
  }

  render() {
    const { isNumberTaken, reprovisionNumber, setChooseNumber, subscription } = this.props;

    return (
      <PickNumberPage
        areaCode={this.areaCode}
        isNumberTaken={isNumberTaken}
        reprovisionNumber={reprovisionNumber}
        setSelectedNumber={setChooseNumber}
        subscription={subscription}
      />
    );
  }
}

PickNumberPageContainer.propTypes = propTypes;
PickNumberPageContainer.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  isNumberTaken: state.subscribePage.isNumberTaken,
  subscription: state.subscribePage.subscription,
});

const mapDispatchToProps = actionCreators;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickNumberPageContainer);
